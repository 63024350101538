import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="my-5 sm:w-3/5 4/5 mx-auto p-4">
       <h1 className=" mb-5">Privacy Policy</h1>
       <p><strong>Effective Date:</strong> September 27, 2024</p>

    <p>OneScript ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you use our website, services, and online forms, including the OneScript pain management form ("Services"). Please read this Privacy Policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>

    <h2>1. Information We Collect</h2>
    <p>We collect several types of information from you when you use our Services:</p>
    <ul>
        <li><strong>Personal Information:</strong> This may include your name, email address, phone number, age, and other information you provide directly through our forms.</li>
        <li><strong>Health Information:</strong> When you use forms like our pain assessment form, we may collect health-related information such as allergies, conditions (e.g., methemoglobinemia), and reasons for purchasing pain management products.</li>
        <li><strong>Payment Information:</strong> If you proceed to a checkout page, we collect your payment details through our third-party payment processors (e.g., Stripe).</li>
        <li><strong>Technical Data:</strong> We may automatically collect data about your browser, device, and IP address when you use our website.</li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <p>We use the information we collect in the following ways:</p>
    <ul>
        <li><strong>To provide our services:</strong> We collect and use your information to deliver our Services, including processing your form submissions and product orders.</li>
        <li><strong>To improve user experience:</strong> We analyze usage data and feedback to improve the functionality and user experience of our website.</li>
        <li><strong>To comply with legal obligations:</strong> We may be required to process your information to comply with laws and regulations, such as medical or financial regulations.</li>
        <li><strong>To prevent fraud and abuse:</strong> We use the information collected to monitor for potential fraud or misuse of our Services.</li>
    </ul>

    <h2>3. Sharing Your Information</h2>
    <p>We do not sell, trade, or rent your personal information to third parties. We may share your information in the following circumstances:</p>
    <ul>
        <li><strong>Third-party service providers:</strong> We may share your information with trusted third-party service providers who perform services on our behalf, such as payment processors (e.g., Stripe) and cloud hosting services.</li>
        <li><strong>Legal requirements:</strong> We may disclose your information if required by law, regulation, or legal process, such as in response to a subpoena or court order.</li>
        <li><strong>Business transfers:</strong> In the event of a merger, acquisition, or sale of assets, we may transfer your information as part of the transaction.</li>
    </ul>

    <h2>4. Data Security</h2>
    <p>We take reasonable measures to protect your information from unauthorized access, loss, misuse, or alteration. We use encryption, secure servers, and other industry-standard technologies to safeguard your information. However, please note that no data transmission or storage can be guaranteed to be 100% secure.</p>

    <h2>5. Your Rights</h2>
    <p>Depending on your location, you may have certain rights regarding the personal data we collect about you. These may include:</p>
    <ul>
        <li>The right to access your personal data.</li>
        <li>The right to correct inaccurate or incomplete data.</li>
        <li>The right to request deletion of your personal data.</li>
        <li>The right to object to or restrict data processing.</li>
        <li>The right to data portability.</li>
    </ul>
    <p>To exercise your rights, please contact us at <a href="mailto:info@onescript.ca">info@onescript.ca</a>.</p>

    <h2>6. Cookies</h2>
    <p>We use cookies and similar tracking technologies to collect information about your browsing activities on our website. Cookies help us to improve our Services and provide a better user experience. You can control the use of cookies by adjusting your browser settings.</p>

    <h2>7. Third-Party Links</h2>
    <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of those websites. Please review the privacy policies of any third-party websites before providing your information to them.</p>

    <h2>8. Children's Privacy</h2>
    <p>Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children. If we learn that we have collected personal data from a child under 13, we will take steps to delete that information as quickly as possible.</p>

    <h2>9. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Effective Date." Please check back periodically to review any updates.</p>

    <h2>10. Contact Us</h2>
    <p>If you have any questions about this Privacy Policy or how we handle your personal information, please contact us at:</p>
    <ul>
        <li><strong>Email:</strong> <a href="mailto:info@onescript.ca">info@onescript.ca</a></li>
        <li><strong>Address:</strong> 391 Matheson Blvd E B, Mississauga, ON L4Z 2H2</li>
    </ul>
    
   </div>
    );
};

export default PrivacyPolicy;