import React from 'react';

const TermsNConditions = () => {
    return (
        <div className="my-5 sm:w-3/5 4/5 mx-auto p-4">
            <h1 className=" mb-5">Terms and Conditions</h1>
    <p><strong>Effective Date:</strong> September 27, 2024</p>

    <p>Welcome to OneScript! These terms and conditions ("Terms") outline the rules and regulations for the use of OneScript's website and services. By accessing or using our website at <a href="https://www.onescript.ca">www.onescript.ca</a> ("Website") and our services, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our services.</p>

    <h2>1. Use of the Website</h2>
    <p>By using the OneScript Website and services, you confirm that you are at least 18 years old, or that you have obtained parental or guardian consent if you are under 18. You agree to use the Website only for lawful purposes and in accordance with these Terms.</p>

    <h2>2. Account Registration</h2>
    <p>In order to access certain features of our services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</p>

    {/* <h2>3. Health and Medical Information Disclaimer</h2>
    <p>The information provided on the OneScript Website, including information related to pain relief products and treatment plans, is for general informational purposes only and is not intended as medical advice. It is not a substitute for professional medical advice, diagnosis, or treatment. Always consult with a qualified healthcare provider before using any products or services offered through OneScript.</p> */}

    <h2>3. Products and Services</h2>
    <p>All products offered through OneScript are subject to availability. We reserve the right to modify or discontinue any product or service at any time without notice. Prices for our products and services are subject to change at our discretion.</p>

    <h2>4. Orders and Payments</h2>
    <p>When placing an order for any product or service, you agree to provide accurate and complete information. Payments for orders are processed securely through third-party payment processors (e.g., Stripe). By completing a purchase, you agree to our payment terms. We reserve the right to cancel or refuse any order at our discretion.</p>

    <h2>5. Refunds and Cancellations</h2>
    <p>Due to the nature of our products, we do not offer refunds once a purchase has been completed, unless required by law. If you experience any issues with your order, please contact us at <a href="mailto:info@onescript.ca">info@onescript.ca</a>.</p>

    <h2>6. User Responsibilities</h2>
    <p>As a user of the Website and our services, you agree not to:</p>
    <ul>
        <li>Engage in any unlawful or harmful behavior while using the Website.</li>
        <li>Attempt to access or use another user's account without permission.</li>
        <li>Upload or transmit viruses, malware, or any other harmful content.</li>
        <li>Use the Website or services for any fraudulent, deceptive, or illegal purposes.</li>
    </ul>

    <h2>7. Intellectual Property</h2>
    <p>All content on the OneScript Website, including text, graphics, logos, and software, is the intellectual property of OneScript or its licensors and is protected by intellectual property laws. You may not use, reproduce, distribute, or modify any content from the Website without our prior written permission.</p>

    <h2>8. Limitation of Liability</h2>
    <p>To the fullest extent permitted by law, OneScript and its affiliates shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the Website or services. This includes, but is not limited to, any errors or omissions in the information provided, or any loss or damage incurred as a result of the use of our products or services.</p>

    <h2>9. Indemnification</h2>
    <p>You agree to indemnify and hold OneScript and its affiliates harmless from any claims, liabilities, damages, losses, or expenses (including legal fees) arising out of or related to your use of the Website, your violation of these Terms, or your infringement of any third-party rights.</p>

    <h2>10. Changes to These Terms</h2>
    <p>We may update these Terms from time to time. Any changes will be posted on this page with an updated "Effective Date." Your continued use of the Website and services after any changes have been made constitutes acceptance of the new Terms.</p>

    <h2>11. Governing Law</h2>
    <p>These Terms shall be governed by and construed in accordance with the laws of the Province of Ontario, without regard to its conflict of law principles. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts in Ontario, Canada.</p>

    <h2>12. Contact Us</h2>
    <p>If you have any questions or concerns about these Terms and Conditions, please contact us at:</p>
    <ul>
        <li><strong>Email:</strong> <a href="mailto:info@onescript.ca">info@onescript.ca</a></li>
        <li><strong>Address:</strong> 391 Matheson Blvd E B, Mississauga, ON L4Z 2H2</li>
    </ul>
        </div>
    );
};

export default TermsNConditions;